import React from 'react';

import styles from './download-link.module.scss';

const DownloadLink = ({ url, title, mime }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className={styles.root}
  >
    <span className={styles.title}>{title}</span>
    <span className={styles.mime}>{mime}</span>
    <span>&#8595;</span>
  </a>
);

export default DownloadLink;
