import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import SEO from '../components/seo';
import { HeroState } from '../components/hero/hero-context';
import ContentColumn from '../components/content-column/content-column';
import PageWrapper from '../components/page-wrapper/page-wrapper';
import NewsletterBox from '../components/newsletter-box/newsletter-box';
import DownloadLink from '../components/download-link/download-link';

const PrivacyPage = () => {
  const { t } = useTranslation('press');

  return (
    <PageWrapper>
      <SEO title={t('title')} />
      <HeroState title={t('header.title')} imageName="machine--side-white" />
      <Container>
        <Row>
          <ContentColumn md={{ offset: 1, span: 10 }}>
            <Trans i18nKey="content.top" ns="press">
              <p>
                Bei uns passiert gerade sehr viel: die erste limitierte Serie
                der MANUMENT Leva-Maschine ist nun in Produktion und wir
                bereiten und auf die ersten Auslieferungen Anfang 2021 vor. Wenn
                Sie uns auf dem spannenden Weg begleiten möchten, dann freuen
                wir uns sehr über eine Anfrage.
              </p>
              <p className="mb-5">
                Im Folgenden finden Sie einige Informationen.Bei
                Veröffentlichung bitten wir um ein Belegexemplar oder die
                Zusendung des Links.
              </p>
            </Trans>
            <p className="mb-5">
              <DownloadLink
                title={t('documents.0.title')}
                mime="pdf"
                url={t('documents.0.url')}
              />
              <DownloadLink
                title={t('documents.1.title')}
                mime="pdf"
                url={t('documents.1.url')}
              />
            </p>
            <Trans i18nKey="content.bottom" ns="press">
              <p>
                Pressekontakt:{' '}
                <a href="mailto:press@manument.com">press@manument.com</a>
              </p>
            </Trans>
          </ContentColumn>
        </Row>
      </Container>
      <NewsletterBox />
    </PageWrapper>
  );
};

export default PrivacyPage;
